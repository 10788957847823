//copy blog template + add to gatsby-node, switch out postoption component for new bookList component? Or perhaps use grid and pass in numRows?
//(mught be able to refactor grid to be used for posts and books, just changing num rows for preference)

import React from "react"
import { graphql } from "gatsby"
import styled, { css } from "styled-components"
import { SEO, Layout, Book } from "../components/index"

// Declaring query here allows us to shadow components
export const query = graphql`
  query ($slug: String!) {
    sanityPages(slug: { current: { eq: $slug } }) {
      title
      description
      _rawSections
      slug {
        current
      }
    }

    allSanityBooks(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          title
          author {
            name
            slug {
              current
            }
          }
          publishedAt(formatString: "MMM D, yyyy")
          description
          cover {
            asset {
              url
              gatsbyImage(
                width: 800
                layout: CONSTRAINED
                placeholder: BLURRED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`

const BooksTemplate = (props) => {
  let page = props.data.sanityPages
  let books = props.data.allSanityBooks.edges
  let parent = props.data.sanityPages.slug.current

  for (var book in books) {
    if (books[book].node) {
      books[book] = {
        title: books[book].node.title,
        cover: books[book].node.cover,
        description: `${books[book].node.description}`,
        slug: { current: `/${parent}/${books[book].node.slug.current}` },
        author: books[book].node.author,
        publishedAt: books[book].node.publishedAt,
      }
      delete books[book].node
    }
  }

  books = { books }
  //filter based on tag, sort based on tag, publisheddate, title - make a postsOptions component and call Grid with updated books

  return (
    <Layout>
      {/*<h1>{page.title}</h1>*/}
      <Books>
        {books.books.map((book) => (
          <StyledBook key={book.title}>
            <Book.Cover
              src={book.cover}
              alt={book.title}
              link={book.slug.current}
            />
            <Book.Body>
              <StyledTitle link={book.slug.current} styledAs="h2">
                {book.title}
              </StyledTitle>
              <Book.Author
                name={book.author.name}
                link={book.author.slug.current}
              />
            </Book.Body>
          </StyledBook>
        ))}
      </Books>
    </Layout>
  )
}

const booksStyle = ({ theme }) => css`
  display: grid;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  font-size: ${theme.fontSize["2xs"]};

  ${theme.breakpoints["sm"]`
  font-size: ${theme.fontSize["sm"]};
  `}

  ${theme.breakpoints["md"]`
    gap: ${theme.spaces[8]};
  `}
`

const Books = styled.div([booksStyle])

const StyledBook = styled(Book)`
  display: flex;
  flex-direction: column;
  margin-top: 0;
  text-align: center;

  div {
    margin-left: 0;
  }
`

const StyledTitle = styled(Book.Title)``

export default BooksTemplate

export function Head({ data }) {
  let { title, description } = data.sanityPages

  return <SEO title={title} description={description} />
}
